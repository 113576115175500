<template>
  <div>
    <v-dialog v-model="openModal" max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="text-center font-weight-bold">LISTA DE HISTORIAL DE VENTAS</p>
            </v-col>

            <v-col cols="12" v-show="ventas.length > 0">
              <v-simple-table class="tabla-ventas">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th class="px-15">CODIGO VENTA</th>
                    <th class="px-15">MONTO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, index) in ventas" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <p v-text="v.codigo_venta"></p>
                    </td>
                    <td>
                      <p v-text="v.total_final"></p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn class="mr-2" color="error" outlined="" @click="openModal = false">
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idcliente: "",
      ventas: [],
      mostrarContenido: false,
    };
  },
  props: {
    objModalHistorialVentas: {
      type: Object,
    },
  },
  watch: {
    objModalHistorialVentas(val) {
      this.openModal = val.openModal;
      this.idcliente = val.idcliente;
      this.ventas = [];
      this.getHistorialVentas();
    },
  },

  methods: {
    getHistorialVentas() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/getHistorialVentas",
        data: {
          idcliente: this.idcliente,
        },
      })
        .then((response) => {
          const ventas = response.data;
          this.ventas = ventas;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },
  },

  created() {},
};
</script>

<style scoped>
.tabla-ventas {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-ventas thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-ventas tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
