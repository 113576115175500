<template>
  <v-container fluid>
    <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
    <modal-direccion :objModalDireccion="objModalDireccion"></modal-direccion>
    <modal-favoritos :objModalFavoritos="objModalFavoritos"></modal-favoritos>
    <modal-medidas :objModalMedidas="objModalMedidas"></modal-medidas>
    <modal-historial-ventas
      :objModalHistorialVentas="objModalHistorialVentas"
    ></modal-historial-ventas>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> GESTIONAR CLIENTES </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <div class="col-12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'clientesPanel' }"
                    >Clientes</router-link
                  >
                </p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn
                  v-show="validarPermiso('clientes.listado')"
                  :to="{ name: 'clientesCrearPanel' }"
                  color="primary"
                  dark
                  large=""
                >
                  <v-icon left="" large="">mdi-plus-circle</v-icon> NUEVO CLIENTE
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  title="Limpiar filtros"
                  fab
                  dark
                  color="blue-grey"
                  depressed
                  @click="limpiarFiltros()"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                <v-select
                  v-model="cmbCantidadRegistros"
                  item-text="texto"
                  item-value="valor"
                  :items="arrCantidadRegistros"
                  label="Cantidad de registros"
                  @change="listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col xl="10" lg="10" md="2" sm="12" cols="12">
                <v-text-field
                  dense
                  label="Buscar registro"
                  prepend-icon="mdi-magnify"
                  clearable
                  type="text"
                  v-model="iptBuscarRegisro"
                  @keyup.enter="
                    listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)
                  "
                  @click:clear="listarRegistros(cmbCantidadRegistros, null, null)"
                  @keyup.delete="
                    iptBuscarRegisro === ''
                      ? listarRegistros(cmbCantidadRegistros, null, null)
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :loading="btnReporteExcel"
                  fab
                  elevation=""
                  @click="reporteExcel()"
                >
                  <v-icon large color="success">mdi-file-excel</v-icon>
                </v-btn>
                <v-btn class="ml-5" small fab elevation="" @click="reporteExcelSimple()">
                  <v-icon small color="success">mdi-file-excel</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-simple-table dense="" fixed-header="" ref="tblListado">
                  <template v-slot:default>
                    <thead class="text-no-wrap">
                      <tr>
                        <th>ACCIONES</th>
                        <th>COD. CLIENTE</th>
                        <th>NOMBRES</th>
                        <th>N° DOCUMENTO</th>
                        <th>TELÉFONO</th>
                        <th>EMAIL</th>
                        <th>N° DE PRODUCTOS EN CARRITO</th>
                        <th>DIRECCIONES</th>
                        <th>MEDIDAS</th>
                        <th>FAVORITOS</th>
                        <th>HISTORIAL DE VENTAS</th>
                      </tr>
                    </thead>
                    <tbody class="text-no-wrap">
                      <tr v-for="item in arrRegistrosTabla" :key="item.idcliente">
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                dark=""
                                depressed=""
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left="">mdi-cursor-default-click</v-icon>
                                Acciones
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-show="validarPermiso('clientes.editar')"
                                :to="{
                                  name: 'clientesEditarPanel',
                                  params: { idcliente: item.idcliente },
                                }"
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-pencil</v-icon> Editar
                                </v-list-item-title>
                              </v-list-item>

                              <v-list-item
                                v-show="validarPermiso('clientes.eliminar')"
                                @click="
                                  objModalEliminar = {
                                    idcliente: item.idcliente,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-trash-can</v-icon> Eliminar
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                        <td v-text="item.codigo_cliente"></td>
                        <td v-text="item.nombres + ' ' + item.apellidos"></td>
                        <td>
                          <span v-text="item.tipo_documento.nombre"></span>
                          <br />
                          <span v-text="item.numero_documento_identidad"></span>
                        </td>
                        <td v-text="item.telefono"></td>
                        <td v-text="item.correo"></td>
                        <td class="text-center">
                          <v-badge left color="error">
                            <span slot="badge" v-text="item.detalle_carrito.cantidad"
                              >}</span
                            >
                          </v-badge>
                          <br />
                          <v-badge left color="error">
                            <span
                              slot="badge"
                              v-text="'S/. ' + item.detalle_carrito.total_final"
                            ></span>
                          </v-badge>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="
                              objModalDireccion = {
                                openModal: true,
                                idcliente: item.idcliente,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="
                              objModalMedidas = {
                                openModal: true,
                                idcliente: item.idcliente,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="
                              objModalFavoritos = {
                                openModal: true,
                                idcliente: item.idcliente,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="
                              objModalHistorialVentas = {
                                openModal: true,
                                idcliente: item.idcliente,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p>
                  Mostrando del registro <span v-text="primerRegistro"></span> al
                  <span v-text="ultimoRegistro"></span> de un total de
                  <span v-text="totalRegistro"></span> filas
                </p>
              </v-col>

              <v-col cols="12 text-center" v-show="loaderListado">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-pagination
                  circle
                  v-model="paginaActual"
                  :length="cantidadcliente"
                  :total-visible="7"
                  @input="
                    listarRegistros(cmbCantidadRegistros, paginaActual, iptBuscarRegisro)
                  "
                  :disabled="loaderListado"
                ></v-pagination>
              </v-col>

              <v-col cols="12" v-show="alertRegistroNoEcontrado">
                <v-alert type="error" dense outlined class="text-center">
                  NO HAY REGISTROS DISPONIBLES PARA MOSTRAR
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modalEliminar from "./Eliminar";
import modalDireccion from "./Direccion";
import modalFavoritos from "./Favoritos";
import modalMedidas from "./Medidas";
import modalHistorialVentas from "./HistorialVentas";
export default {
  components: {
    modalEliminar,
    modalDireccion,
    modalFavoritos,
    modalMedidas,
    modalHistorialVentas,
  },
  data() {
    return {
      arrCantidadRegistros: [
        {
          texto: 10,
          valor: 10,
        },
        {
          texto: 30,
          valor: 30,
        },
        {
          texto: 50,
          valor: 50,
        },
        {
          texto: 100,
          valor: 100,
        },
        {
          texto: 500,
          valor: 500,
        },
      ],
      loaderListado: false,
      alertRegistroNoEcontrado: false,
      cmbCantidadRegistros: 10,
      arrRegistrosTabla: [],
      paginaActual: 1,
      cantidadcliente: 1,
      primerRegistro: 0,
      ultimoRegistro: 0,
      totalRegistro: 0,
      iptBuscarRegisro: "",
      objModalEliminar: null,
      objModalDireccion: null,
      objModalFavoritos: null,
      objModalMedidas: null,
      objModalHistorialVentas: null,
      btnReporteExcel: false,
    };
  },

  methods: {
    listarRegistros(
      cantidadRegistros = null,
      paginaActual = null,
      iptBuscarRegisro = null
    ) {
      this.arrRegistrosTabla = [];
      this.loaderListado = true;
      this.alertRegistroNoEcontrado = false;
      this.axios({
        method: "GET",
        url: "api/panel/clientes",
        params: {
          cantidadRegistros: cantidadRegistros,
          paginaActual: paginaActual,
          iptBuscarRegisro: iptBuscarRegisro,
        },
      })
        .then((response) => {
          let listado = response.data.data;

          this.arrRegistrosTabla = listado.data;
          this.paginaActual = listado.current_page;
          this.cantidadcliente = listado.last_page;
          this.primerRegistro = listado.from;
          this.ultimoRegistro = listado.to;
          this.totalRegistro = listado.total;

          if (this.arrRegistrosTabla.length === 0) {
            this.alertRegistroNoEcontrado = true;
          } else {
            this.alertRegistroNoEcontrado = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loaderListado = false;
          this.$vuetify.goTo(this.$refs.tblListado);
        });
    },

    reporteExcel() {
      window.open(this.apiUrl("api/panel/clientes/reporteExcel"), "_self");

      /*this.btnReporteExcel = true;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/reporteExcel",
        responseType: "blob",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let objUrl = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.setAttribute("href", objUrl);
            link.setAttribute(
              "download",
              "reporte_clientes_" + this.$moment().format("DD-MM-YYYY_hh-mm-ss")
            );
            link.click();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.btnReporteExcel = false;
        });*/
    },

    reporteExcelSimple() {
      window.open(this.apiUrl("api/panel/clientes/simple/reporteExcelSimple"), "_self");
    },

    limpiarFiltros() {
      this.cmbCantidadRegistros = 10;
      this.iptBuscarRegisro = "";
      this.listarRegistros();
    },
  },

  created() {
    if (!this.validarPermiso("clientes.listado")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    this.listarRegistros();
  },
};
</script>
