<template>
  <div>
    <v-dialog v-model="openModal" max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="text-center font-weight-bold">LISTADO DIRECCIONES</p>
            </v-col>

            <v-col cols="12" class="text-left">
              <v-btn color="info" @click="agregarDireccion"> AGREGAR DIRECCION </v-btn>
            </v-col>

            <v-col cols="12" v-show="direcciones.length > 0">
              <v-simple-table class="tabla-direcciones">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th class="px-15">TIPO DIRECCIÓN</th>
                    <th class="px-15">DIRECCIÓN</th>
                    <th class="px-15">REFERENCIA</th>
                    <th class="px-15">DEPARTAMENTO</th>
                    <th class="px-15">PROVINCIA</th>
                    <th class="px-15">DISTRITO</th>
                    <th>ELIMINAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, index) in direcciones" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="nombre"
                        item-value="idtipo_direccion"
                        dense
                        :items="cmbTipoDireccion"
                        label="TIPO DIRECCIÓN"
                        v-model="d.idtipo_direccion"
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        class="mt-6"
                        dense
                        label="DIRECCIÓN"
                        v-model="d.direccion"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        class="mt-6"
                        dense
                        label="REFERENCIA"
                        v-model="d.referencia"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        @change="getProvincia(index, d.iddepartamento, true)"
                        item-text="nombre"
                        item-value="iddepartamento"
                        dense
                        :items="cmbDepartamento"
                        label="DEPARTAMENTO"
                        v-model="d.iddepartamento"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        @change="getDistrito(index, d.iddepartamento, d.idprovincia)"
                        item-text="nombre"
                        item-value="idprovincia"
                        dense
                        :items="d.cmbProvincia"
                        label="PROVINCIA"
                        v-model="d.idprovincia"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="nombre"
                        item-value="iddistrito"
                        dense
                        :items="d.cmbDistrito"
                        label="DISTRITO"
                        v-model="d.iddistrito"
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="eliminarDireccion(index, d.iddireccion)"
                        color="error"
                        small
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>

              <v-btn
                v-show="direcciones.length > 0"
                :loading="processForm"
                class="mr-2"
                color="success"
                @click="modificarDireccion()"
              >
                <v-icon left="">mdi-cached</v-icon> Modificar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idcliente: "",
      direcciones: [],
      cmbDepartamento: [],
      cmbTipoDireccion: [],
      processForm: false,
      mostrarContenido: false,
    };
  },
  props: {
    objModalDireccion: {
      type: Object,
    },
  },
  watch: {
    objModalDireccion(val) {
      this.openModal = val.openModal;
      this.idcliente = val.idcliente;
      this.direcciones = [];
      this.getDirecciones();
    },
  },

  methods: {
    getDirecciones() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/getDirecciones",
        data: {
          idcliente: this.idcliente,
        },
      })
        .then((response) => {
          const direcciones = response.data;

          direcciones.forEach((element, index) => {
            this.direcciones.push({
              iddepartamento: element.iddepartamento,
              iddireccion: element.iddireccion,
              iddistrito: element.iddistrito,
              idprovincia: element.idprovincia,
              idtipo_direccion: element.idtipo_direccion,
              referencia: element.referencia,
              direccion: element.direccion,
              cmbProvincia: [],
              cmbDistrito: [],
            });

            if (element.idprovincia != "" && element.idprovincia != null) {
              this.getProvincia(index, element.iddepartamento);
            }

            if (element.iddistrito != "" && element.iddistrito != null) {
              this.getDistrito(index, element.iddepartamento, element.idprovincia);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    agregarDireccion() {
      this.direcciones.push({
        iddepartamento: "",
        iddireccion: "",
        iddistrito: "",
        idprovincia: "",
        idtipo_direccion: "",
        referencia: "",
        direccion: "",
        cmbProvincia: [],
        cmbDistrito: [],
      });
    },

    eliminarDireccion(index, iddireccion) {
      if (iddireccion === "" || iddireccion == null) {
        this.$delete(this.direcciones, index);
      } else {
        this.axios({
          method: "POST",
          url: "api/panel/clientes/eliminarDireccion",
          data: {
            iddireccion: iddireccion,
          },
        })
          .then((response) => {
            this.$toasted.success(response.data, {
              icon: "mdi-check-bold",
            });

            this.$delete(this.direcciones, index);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.openModal = false;
          });
      }
    },

    getTipoDireccion() {
      this.axios({
        method: "POST",
        url: "api/panel/clientes/getTipoDireccion",
      })
        .then((response) => {
          this.cmbTipoDireccion = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    getDepartamento() {
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/departamentos",
      })
        .then((response) => {
          this.cmbDepartamento = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    getProvincia(index, iddepartamento, limpiar = false) {
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/provincias",
        data: {
          iddepartamento: iddepartamento,
        },
      })
        .then((response) => {
          this.direcciones[index].cmbProvincia = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (limpiar) {
            this.direcciones[index].cmbDistrito = [];
            this.direcciones[index].idprovincia = "";
            this.direcciones[index].iddistrito = "";
          }
        });
    },

    getDistrito(index, iddepartamento, idprovincia) {
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/distritos",
        data: {
          iddepartamento: iddepartamento,
          idprovincia: idprovincia,
        },
      })
        .then((response) => {
          this.direcciones[index].cmbDistrito = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    modificarDireccion() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/modificarDireccion",
        data: {
          idcliente: this.idcliente,
          direcciones: this.direcciones,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data, {
              icon: "mdi-check-bold",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al modificar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
          this.openModal = false;
        });
    },
  },

  created() {
    this.getDepartamento();
    this.getTipoDireccion();
  },
};
</script>

<style scoped>
.tabla-direcciones {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-direcciones thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-direcciones tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
