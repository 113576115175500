<template>
  <div>
    <v-dialog v-model="openModal" max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="text-center font-weight-bold">LISTADO MEDIDAS</p>
            </v-col>

            <v-col cols="12" class="text-left">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small dark="" depressed="" color="cyan" v-bind="attrs" v-on="on">
                    <v-icon left="">mdi-cursor-default-click</v-icon>
                    Agregar Medida
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="agregarMedidaFormulario">
                    <v-list-item-title>
                      <v-icon>mdi-format-align-justify</v-icon> Formulario
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="agregarMedidaArchivo">
                    <v-list-item-title>
                      <v-icon>mdi-prescription</v-icon> Archivo
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-simple-table class="tabla-medidas" v-show="medidasFormulario.length > 0">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th class="px-15">FECHA</th>
                    <th class="px-15">NOMBRE</th>
                    <th class="px-15">OD ESFERA</th>
                    <th class="px-15">OD CILINDRO</th>
                    <th class="px-15">OD EJE</th>
                    <th class="px-15">OI ESFERA</th>
                    <th class="px-15">OI CILINDRO</th>
                    <th class="px-15">OI EJE</th>
                    <th class="px-15">DIP</th>
                    <th class="px-15">ADICIÓN</th>
                    <th class="px-15">ELIMINAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(medida, index) in medidasFormulario" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <v-menu :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mt-6"
                            :value="formatFecha(medida.fecha)"
                            dense
                            clearable
                            label="Fecha"
                            readonly
                            @click:clear="medida.fecha = ''"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="medida.fecha"
                          locale="es-es"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        class="mt-6"
                        dense
                        label="Nombre"
                        v-model="medida.nombre"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.esferas"
                        label="ESFERA"
                        v-model="medida.od_esfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.cilindros"
                        label="CILINDRO"
                        v-model="medida.od_cilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.ejes"
                        label="EJE"
                        v-model="medida.od_eje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.esferas"
                        label="ESFERA"
                        v-model="medida.oi_esfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.cilindros"
                        label="CILINDRO"
                        v-model="medida.oi_cilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.ejes"
                        label="EJE"
                        v-model="medida.oi_eje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.dips"
                        label="DIP"
                        v-model="medida.dip"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.adiciones"
                        label="Adición"
                        v-model="medida.adicion"
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="eliminarMedida(index, medida.idmedida, 'formulario')"
                        color="error"
                        small
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table
                class="tabla-medidas mt-3"
                v-show="medidasArchivo.length > 0"
              >
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th class="px-15">ARCHIVO</th>
                    <th class="px-15">NOMBRE</th>
                    <th class="px-15">FECHA</th>
                    <th class="px-15">ELIMINAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(medida, index) in medidasArchivo" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        :href="assetAWS('ventas/recetas_subidas/' + medida.ruta)"
                        v-show="medida.ruta != null && medida.ruta != ''"
                      >
                        <v-img
                          v-show="medida.ruta != null && medida.ruta != ''"
                          contain=""
                          height="60"
                          width="50"
                          :src="assetAWS('ventas/recetas_subidas/' + medida.ruta)"
                        ></v-img>
                      </a>
                      <v-file-input
                        accept="image/*"
                        label="Archivo"
                        v-model="medida.archivo"
                      ></v-file-input>
                    </td>
                    <td>
                      <v-text-field
                        class="mt-6"
                        dense
                        label="Nombre"
                        v-model="medida.nombre"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-menu :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mt-6"
                            :value="formatFecha(medida.fecha)"
                            dense
                            clearable
                            label="Fecha"
                            readonly
                            @click:clear="medida.fecha = ''"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="medida.fecha"
                          locale="es-es"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="eliminarMedida(index, medida.idmedida, 'archivo')"
                        color="error"
                        small
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>

              <v-btn
                v-show="medidasFormulario.length > 0 || medidasArchivo.length > 0"
                :loading="processForm"
                class="mr-2"
                color="success"
                @click="modificarMedida()"
              >
                <v-icon left="">mdi-cached</v-icon> Modificar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resources: {
        esferas: [],
        cilindros: [],
        ejes: [],
        adiciones: [],
        dips: [],
        anios: [],
        meses: [],
      },
      openModal: false,
      idcliente: "",
      medidasFormulario: [],
      medidasArchivo: [],
      processForm: false,
      mostrarContenido: false,
    };
  },
  props: {
    objModalMedidas: {
      type: Object,
    },
  },
  watch: {
    objModalMedidas(val) {
      this.openModal = val.openModal;
      this.idcliente = val.idcliente;
      this.medidasFormulario = [];
      this.medidasArchivo = [];
      this.getMedidas();
    },
  },

  methods: {
    getMedidas() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/getMedidas",
        data: {
          idcliente: this.idcliente,
        },
      })
        .then((response) => {
          const medidas = response.data;
          medidas.forEach((element, index) => {
            if (element.idmetodo_envio_medida == 1) {
              this.medidasFormulario.push({
                  idmedida              : element.idmedida,
                  idcliente             : element.idcliente,
                  idmetodo_envio_medida : element.idmetodo_envio_medida,
                  nombre                : element.nombre,
                  od_esfera             : element.od_esfera,
                  od_cilindro           : element.od_cilindro,
                  od_eje                : element.od_eje,
                  oi_esfera             : element.oi_esfera,
                  oi_cilindro           : element.oi_cilindro,
                  oi_eje                : element.oi_eje,
                  dip                   : element.dip,
                  adicion               : element.adicion,
                  fecha                 : element.fecha,
                  fecha_create          : element.fecha_create,
                  estado                : element.estado
              });
            } else {
              this.medidasArchivo.push({
                idmedida: element.idmedida,
                idcliente: element.idcliente,
                idmetodo_envio_medida: element.idmetodo_envio_medida,
                nombre: element.nombre,
                fecha: element.fecha,
                ruta: element.ruta,
                archivo: null,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    agregarMedidaFormulario() {
      this.medidasFormulario.push({
        idmedida: "",
        idcliente: "",
        idmetodo_envio_medida: 1,
        nombre: "",
        od_esfera: "",
        od_cilindro: "",
        od_eje: "",
        oi_esfera: "",
        oi_cilindro: "",
        oi_eje: "",
        dip: "",
        adicion: "",
        fecha: "",
        ruta: "",
      });
    },

    agregarMedidaArchivo() {
      this.medidasArchivo.push({
        idmedida: "",
        idcliente: "",
        idmetodo_envio_medida: 2,
        nombre: "",
        fecha: "",
        ruta: "",
        archivo: null,
      });
    },

    eliminarMedida(index, idmedida, tipo) {
      if (idmedida === "" || idmedida == null) {
        if (tipo === "formulario") {
          this.$delete(this.medidasFormulario, index);
        } else {
          this.$delete(this.medidasArchivo, index);
        }
      } else {
        this.axios({
          method: "POST",
          url: "api/panel/clientes/eliminarMedida",
          data: {
            idmedida: idmedida,
          },
        })
          .then((response) => {
            this.$toasted.success(response.data, {
              icon: "mdi-check-bold",
            });

            if (tipo === "formulario") {
              this.$delete(this.medidasFormulario, index);
            } else {
              this.$delete(this.medidasArchivo, index);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.openModal = false;
          });
      }
    },

    formatFecha(fecha) {
      return fecha != null && fecha != "" ? this.$moment(fecha).format("DD/MM/YYYY") : "";
    },

    valoresRango(inicio, final, contador, texto = "", decimal) {
      let valores = [];
      for (inicio; inicio <= final; inicio += contador) {
        valores.push({
          texto: inicio.toFixed(decimal) + texto,
          valor: inicio.toFixed(decimal),
        });
      }
      return valores;
    },

    modificarMedida() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/clientes/modificarMedida",
        data: this.serialize(
          {
            idcliente: this.idcliente,
            medidasFormulario: this.medidasFormulario,
            medidasArchivo: this.medidasArchivo,
          },
          {
            indices: true,
          }
        ),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data, {
              icon: "mdi-check-bold",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al modificar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
          this.openModal = false;
        });
    },
  },

  created() {
    const esferas    = [];  // -25.00  0.25  +20.00
    const cilindros  = [];  // 0.00    0.25  -8.00
    const ejes       = [];  // 0°      1     180°
    const adiciones  = [];  // +0.75   0.25  +4.00
    const distancias = [];  // 40 mm   1     80 mm

    for (let i = -25; i<=20; i += 0.25) {
        esferas.push({
          valor: i.toFixed(2),
          texto: (i>0 ? '+' : '')+i.toFixed(2)
        });

        if ( -8 <= i  && i < 0 ) {
            cilindros.push({
              valor: i.toFixed(2),
              texto: i.toFixed(2)
            });
        }

        if ( 0.75 <= i && i <= 4) {
            adiciones.push({
              valor: i.toFixed(2),
              texto: (i>0 ? '+' : '')+i.toFixed(2)
            });
        }

    }

    for (let i = 0; i <= 180; i++) {
        ejes.push({
          texto: i,
          valor: i
        });

        if ( 40 <= i && i <= 80) {
          distancias.push({
            texto: i,
            valor: i
          });
        }

    }


    this.resources.esferas    = esferas;
    this.resources.cilindros  = cilindros;
    this.resources.ejes       = ejes;
    this.resources.adiciones  = adiciones;
    this.resources.dips       = distancias;

    
      
  },
};
</script>

<style scoped>
.tabla-medidas {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-medidas thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-medidas tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
